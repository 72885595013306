import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Link, useLocation, useNavigate } from 'react-router-dom';

import { connect } from 'react-redux';

import map from 'lodash/fp/map';

import AccountIcon from '@mui/icons-material/AccountCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import { blue, green, grey, yellow } from '@mui/material/colors';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha } from '@mui/material/styles';
import styled from '@mui/material/styles/styled';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useOmsDispatch, useOmsSelector } from 'hooks/rtk';

import VIEW_MODE from 'constants/viewModes';

import constructMenu from 'utils/constructMenu';
import getEnv from 'utils/getEnv';

import * as ProductionActions from 'dux/production/actions';
import * as AuthSelectors from 'dux/auth/selectors';
import * as FeatureFlagSelectors from 'dux/featureFlags/selectors';
import getSectionTitle from 'dux/metadata/selectors';
import * as SettingsSelectors from 'dux/settings/selectors';
import * as TeammatesSelectors from 'dux/teammates/selectors';
import * as TeammatesActions from 'dux/teammates/thunks';

import AppSearch from './AppSearch';

const drawerWidth = '240px';

const StyledAppBar = styled(AppBar)`
  position: initial;
  grid-column: 2 / -1;
  grid-row: 1 / 2;
  height: fit-content;
`;

const StyledToolbar = styled(Toolbar)`
  padding-right: 0;
  background-color: ${({ sectiontitle }) => {
    if (sectiontitle === 'Customers') return blue[900];
    if (sectiontitle === 'Orders') return green[900];
    if (sectiontitle === 'Surveys') return yellow[900];
    return grey[900];
  }};
`;

const AppBarVersionInfo = styled('div')`
  margin: ${({ theme }) => theme.spacing(1)};
`;

const IconMenuButton = styled(IconButton)`
  margin: 0 12px;
  & svg {
    fill: white;
    width: 36px;
    height: 36px;
  }
`;

const StyledDrawer = styled(Drawer)`
  grid-column: 1 / 2;
  grid-row: 1 / -1;

  & > div {
    position: initial;
    overflow-x: hidden;
    width: ${({ open }) => (open ? drawerWidth : '59px')};
    transition: ${({ theme, open }) =>
      theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: open
          ? theme.transitions.duration.leavingScreen
          : theme.transitions.duration.enteringScreen,
      })};
    & ul {
      padding-top: 20px;
      & span {
        white-space: nowrap; /* to handle more-than-one-word ListItemTexts */
      }
    }
  }
`;

const OpenNavButton = styled(Button)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  ${({ theme }) => theme.mixins.toolbar}
`;

const AppBarSectionTitle = styled('div')`
  flex: 1;
  & h6 {
    font-weight: 500;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    flex: 0;
  }
`;

const AppBarSearch = styled('div')`
  position: relative;
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  background-color: ${({ theme }) => alpha(theme.palette.common.white, 0.15)};
  flex: 2;

  &:hover {
    background-color: ${({ theme }) => alpha(theme.palette.common.white, 0.25)};
  }
  margin-left: 0;
  width: 100%;
  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-left: ${({ theme }) => theme.spacing(1)};
    margin-right: ${({ theme }) => theme.spacing(1)};
    width: auto;
  }
`;

const AppBarInfo = styled(AppBarSectionTitle)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 30px;
  font-size: 30px;
`;

const SeparationLine = styled('div')`
  margin: 30px 10px 5px;
  border-top: 1px solid black;
`;

const StyledIconButton = styled(IconButton)`
  padding: 8px 16px;
  &:hover {
    background-color: transparent;
  }
`;

const AppMenu = ({
  // ⬇️ redux selectors
  isAdminView,
  isFlagsDoneFetching,
  sectionTitle,
  shouldShowDataOnlyUi,
  userId,
  viewMode,
  // ⬇️ redux actions
  emptyBox,
}) => {
  const dispatch = useOmsDispatch();
  const navigate = useNavigate();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);

  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);

  const location = useLocation();
  const isHidden =
    location.pathname === '/production/palletsqrcodes' ||
    location.pathname === '/production/brushsqrcodes/';
  const menu = useOmsSelector(TeammatesSelectors.getMenuAccess);

  useEffect(() => {
    dispatch(TeammatesActions.fetchSignedInTeammate(userId));
  }, [dispatch, userId]);

  const handleOpenMenu = event => {
    setMenuAnchorEl(event.target);
    setOpenMenu(true);
  };

  if (isHidden) return null;

  const fillListItem = menuItem => {
    if (menuItem.subMenu) {
      return (
        <div key={menuItem.name} data-testid={menuItem.dataTestId}>
          <Menu
            anchorEl={subMenuAnchorEl}
            open={Boolean(subMenuAnchorEl)}
            onClose={event => {
              setSubMenuAnchorEl(null);
              event?.preventDefault?.();
            }}
            onClick={() => setSubMenuAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            slotProps={{
              paper: {
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 2.0,
                  ml: 4.0,
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    left: -5,
                    bottom: 25,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              },
            }}
          >
            {map(
              subMenu => (
                <MenuItem
                  key={subMenu.name}
                  data-testid={subMenu.dataTestId}
                  onClick={event => {
                    setSubMenuAnchorEl(event.currentTarget);
                    navigate(subMenu.to);
                  }}
                >
                  {subMenu.name}
                </MenuItem>
              ),
              menuItem.subMenu
            )}
          </Menu>
          <Tooltip title={menuItem.name}>
            <StyledIconButton onClick={e => setSubMenuAnchorEl(e.currentTarget)}>
              {menuItem.icon}
            </StyledIconButton>
          </Tooltip>
        </div>
      );
    }
    return (
      <ListItem
        key={menuItem.name}
        data-testid={menuItem.dataTestId}
        component={Link}
        to={menuItem.to}
      >
        <ListItemIcon>{menuItem.icon}</ListItemIcon>
        <ListItemText primary={menuItem.name} />
      </ListItem>
    );
  };

  return (
    <>
      <StyledAppBar open={openDrawer}>
        <StyledToolbar
          disableGutters={!isAdminView}
          viewmode={viewMode}
          sectiontitle={sectionTitle}
        >
          {!isAdminView && (
            <Link
              to={VIEW_MODE[viewMode].link}
              data-testid="link-to-lab"
              onClick={event => {
                emptyBox();
                event.target.blur();
              }}
            >
              <IconMenuButton color="inherit" aria-label="open drawer">
                <HomeIcon />
              </IconMenuButton>
            </Link>
          )}
          <AppBarSectionTitle>
            <Typography variant="h6" color="inherit" noWrap data-testid="appBar-title">
              {sectionTitle}
            </Typography>
          </AppBarSectionTitle>
          <AppBarSearch>
            <AppSearch />
          </AppBarSearch>
          <AppBarInfo>
            {isAdminView && (
              <>
                <InfoIcon fontSize="inherit" />
                <AppBarVersionInfo data-sentry-unmask>
                  <Typography variant="body1" color="inherit">
                    BACKOFFICE v{getEnv('REACT_APP_VERSION_BACKOFFICE')}
                  </Typography>
                  <Typography variant="body1" color="inherit">
                    API v{getEnv('REACT_APP_VERSION_API')}
                  </Typography>
                </AppBarVersionInfo>
              </>
            )}
            <AccountIcon data-testid="nav-user-menu" onClick={handleOpenMenu} fontSize="inherit" />
            <Menu
              id="simple-menu"
              anchorEl={menuAnchorEl}
              open={openMenu}
              onClose={() => setOpenMenu(false)}
              data-sentry-unmask
            >
              <MenuItem
                component={Link}
                data-testid="nav-user-account"
                onClick={() => setOpenMenu(false)}
                to="/teammates/me"
              >
                My account
              </MenuItem>
              <MenuItem
                data-testid="nav-user-settings"
                component={Link}
                onClick={() => setOpenMenu(false)}
                to="/settings"
              >
                Settings
              </MenuItem>
              <SeparationLine />
              <MenuItem component={Link} to="/auth/signout">
                Signout
              </MenuItem>
            </Menu>
            <Typography id="sectionTitle" variant="h6" color="inherit" noWrap />
          </AppBarInfo>
        </StyledToolbar>
      </StyledAppBar>
      {isAdminView && (
        <StyledDrawer variant="permanent" open={openDrawer}>
          <OpenNavButton
            onClick={() => setOpenDrawer(prevOpenDrawer => !prevOpenDrawer)}
            aria-label="open drawer"
          >
            {openDrawer ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </OpenNavButton>

          {/* MENU */}
          <List>
            {(!isFlagsDoneFetching || !shouldShowDataOnlyUi) && constructMenu(menu, fillListItem)}
          </List>
        </StyledDrawer>
      )}
    </>
  );
};

AppMenu.propTypes = {
  emptyBox: PropTypes.func.isRequired,
  isAdminView: PropTypes.bool,
  isFlagsDoneFetching: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  sectionTitle: PropTypes.string,
  shouldShowDataOnlyUi: PropTypes.bool,
  userId: PropTypes.string,
  viewMode: PropTypes.string,
};

AppMenu.defaultProps = {
  isAdminView: true,
  isFlagsDoneFetching: false,
  location: null,
  sectionTitle: null,
  shouldShowDataOnlyUi: false,
  userId: null,
  viewMode: 'admin',
};

export default connect(
  state => ({
    userId: AuthSelectors.getUserId(state),
    shouldShowDataOnlyUi: FeatureFlagSelectors.shouldShowDataOnlyUi(state),
    isFlagsDoneFetching: FeatureFlagSelectors.isFlagsDoneFetching(state),
    isAdminView: SettingsSelectors.getIsAdminView(state),
    viewMode: SettingsSelectors.getViewMode(state),
    sectionTitle: getSectionTitle(state),
  }),
  { emptyBox: ProductionActions.emptyBox }
)(AppMenu);
