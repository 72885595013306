import React from 'react';

import omit from 'lodash/fp/omit';

import { Button as DefaultButton, type ButtonProps, CircularProgress } from '@mui/material';
import type { CircularProgressProps } from '@mui/material/CircularProgress/CircularProgress';
import styled from '@mui/material/styles/styled';
import type { Nullable } from 'types/common';

const ButtonCustom = styled(DefaultButton)<{ width?: string | number; margin?: string | number }>`
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
  margin-bottom: ${({ margin }) => (margin === 0 ? 0 : '10px')};
  margin-left: ${({ margin }) => (margin === 0 ? 0 : '10px')};
`;

const LoadingCircularProgress = styled(CircularProgress)(() => ({
  position: 'absolute',
}));

type Props = Omit<ButtonProps, 'size'> & {
  dataTestId?: Nullable<string>;
  isLoading?: boolean;
  loaderColor?: CircularProgressProps['color'];
  margin?: string | number;
  size?: CircularProgressProps['size'];
  type?: Nullable<ButtonProps['type']>;
  width?: string | number;
  'data-sentry-unmask'?: boolean;
};

const Button = ({
  children,
  className,
  color = 'inherit',
  dataTestId,
  disabled = false,
  endIcon,
  fullWidth = false,
  href,
  isLoading = false,
  loaderColor = 'inherit',
  margin,
  onClick,
  size,
  startIcon,
  // @ts-expect-error
  type = null,
  variant,
  width = '100%',
  ...rest
}: Props) => (
  <ButtonCustom
    disabled={disabled || isLoading}
    onClick={onClick}
    className={className}
    color={color}
    variant={variant}
    type={type}
    data-testid={dataTestId}
    endIcon={endIcon}
    fullWidth={fullWidth}
    margin={margin}
    href={href}
    startIcon={startIcon}
    width={width}
    data-sentry-unmask={rest['data-sentry-unmask'] ?? false}
    {...omit(['data-sentry-unmask'], rest)}
  >
    {children}
    {isLoading && <LoadingCircularProgress size={size} color={loaderColor} />}
  </ButtonCustom>
);

export const PrintButton = styled(Button)`
  margin: 0;
  height: 55px;
  align-self: center;
  font-size: 16px;
`;

export default Button;
