import get from 'lodash/fp/get';

import { createSelector } from 'reselect';

import { getProductionState } from '../selectors';

const getMicroholdState = createSelector(getProductionState, get('microhold'));

export const getQualityTestsBatches = createSelector(getMicroholdState, get('quality_tests'));
export const getQualityTestsPages = createSelector(getMicroholdState, get('pages'));

export const getMicroholdPages = createSelector(getMicroholdState, get('pages'));

export const getPallets = createSelector(getMicroholdState, get('pallets'));

export const getPalletTests = createSelector(getMicroholdState, get('palletTests'));

export const getLoadingPalletTests = createSelector(getMicroholdState, get('loadingPalletTests'));

export const getScannedPallet = createSelector(getMicroholdState, get('pallet'));

export const getBoxContainsQualityTestBatch = createSelector(
  getProductionState,
  get('details.box.contains_qualitytest_batch')
);
