import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';

const styles = {
  root: {
    display: 'flex',
    width: '90vw',
    height: '30vh',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  font: {
    fontSize: '1.3em',
    textAlign: 'center',
  },
  again: {
    cursor: 'pointer',
    marginTop: 30,
    textDecoration: 'underline',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
};

const reload = () => window.location.reload();

const handleRefresh = (chunkLoadingError, resetErrorBoundary) => {
  if (chunkLoadingError) return reload;

  return resetErrorBoundary ?? reload;
};

const ErrorComponent = ({ classes, error, resetErrorBoundary }) => {
  const chunkLoadingError =
    error?.message && /^Failed to fetch dynamically imported module:/.test(error.message);

  return (
    <div className={classes.root} data-testid="error-boundary" data-sentry-unmask>
      <div>
        {chunkLoadingError ? (
          <Typography className={classes.font}>
            We’ve updated the site since your last visit.
          </Typography>
        ) : (
          <>
            <Typography className={classes.font}>
              Oops, something went terribly wrong. ¯\\_(ツ)_/¯
            </Typography>
            <Typography data-testid="error-additional-info">
              {error &&
                `Additional info: ${error?.code ?? ''} ${
                  error?.message || error?.body?.detail || error?.data?.detail
                }`}
            </Typography>
          </>
        )}
      </div>

      <Button
        disableRipple
        className={classes.again}
        onClick={handleRefresh(chunkLoadingError, resetErrorBoundary)}
      >
        <Typography className={classes.font}>
          {chunkLoadingError ? `Please, refresh the page.` : `Try Again`}
        </Typography>
      </Button>
      {!chunkLoadingError && (
        <>
          <div>
            <Typography className={classes.font}>or</Typography>
          </div>
          <div>
            <Typography className={classes.font}>
              <a
                href="https://prosehair.slack.com/messages/C01GB6WPZPD/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Let us know on Slack #team-ops-tech-squad
              </a>
            </Typography>
          </div>
        </>
      )}
    </div>
  );
};

ErrorComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
    code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    body: PropTypes.shape({ detail: PropTypes.string }),
    data: PropTypes.shape({ detail: PropTypes.string }),
  }),
  resetErrorBoundary: PropTypes.func,
};

ErrorComponent.defaultProps = {
  error: null,
  resetErrorBoundary: null,
};

export default withStyles(styles)(ErrorComponent);
