import { type Action, type AnyAction, createSlice } from '@reduxjs/toolkit';

import { fetchSignedInTeammate, fetchTeammates } from './thunks';
import type { TeammatesError, TeammatesState } from './types';

const initialState: TeammatesState = {
  teammates: [],
  signedInTeammate: null,
  status: 'idle',
  error: {
    kind: null,
    message: null,
  },
  pages: {},
};

type RejectedAction = Action & { payload: TeammatesError };

function isRejectSignedIn(action: AnyAction): action is RejectedAction {
  return action.payload?.kind === 'fetchSignedInTeammate';
}
function isRejectTeammates(action: AnyAction): action is RejectedAction {
  return action.payload?.kind === 'fetchTeammates';
}

const teammatesSlice = createSlice({
  name: 'teammates',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // fetchSignedInTeammate
      .addCase(fetchSignedInTeammate.pending, draftState => {
        draftState.status = 'pending';
        draftState.signedInTeammate = null;
        draftState.error = {
          kind: null,
          message: null,
        };
      })
      .addCase(fetchSignedInTeammate.fulfilled, (draftState, action) => {
        draftState.status = 'fulfilled';
        draftState.signedInTeammate = action.payload;
      })
      // fetchTeammates
      .addCase(fetchTeammates.pending, draftState => {
        draftState.status = 'pending';
        draftState.teammates = [];
        draftState.error = {
          kind: null,
          message: null,
        };
      })
      .addCase(fetchTeammates.fulfilled, (draftState, action) => {
        const { teammates, pages } = action.payload;

        draftState.status = 'fulfilled';
        draftState.teammates = teammates;
        draftState.pages = pages;
      })
      // attention: matchers need to go the last in the builder chain
      .addMatcher(isRejectSignedIn, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.signedInTeammate = null;
        draftState.error = action.payload;
      })
      .addMatcher(isRejectTeammates, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.teammates = [];
        draftState.error = action.payload;
      });
  },
});

const { reducer } = teammatesSlice;

export default reducer;
