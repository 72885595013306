// Please make sure to add new roles to the teammatesRoles array
// at the bottom of the page in alphabetical order

import * as PERMISSIONS from './teammatesPermissions';

export const ADMINS = {
  name: 'Admins',
  permissions: [
    PERMISSIONS.AUTO_DISPATCH,
    PERMISSIONS.BUFFER_SPACES_LIST,
    PERMISSIONS.COUPONS_VIEW,
    PERMISSIONS.CUSTOMER_LIST,
    PERMISSIONS.CUSTOMER_VIEW,
    PERMISSIONS.DATA_PORTAL,
    PERMISSIONS.DEFECT_ORDER_DETAIL,
    PERMISSIONS.EXPORTS,
    PERMISSIONS.FAST_CONSULTATION,
    PERMISSIONS.FEEDBACK_LIST,
    PERMISSIONS.GIFT_DETAIL,
    PERMISSIONS.GIFT_LIST,
    PERMISSIONS.HELP_BOX_OBJECT_VIEWER,
    PERMISSIONS.HELP_STATUS_VIEWER,
    PERMISSIONS.HELP,
    PERMISSIONS.MANUAL_DISPATCH,
    PERMISSIONS.QUALITY_TEST_PALLETIZE_BOXES,
    PERMISSIONS.QUALITY_TEST_PALLETS,
    PERMISSIONS.NEW_FLOW_PRODUCTION_SCREEN,
    PERMISSIONS.OLD_FLOW_PRODUCTION_SCREEN,
    PERMISSIONS.ORDER_LIST,
    PERMISSIONS.ORDER_VIEW,
    PERMISSIONS.PACK_SCREEN,
    PERMISSIONS.PRINT_COLLATERALS,
    PERMISSIONS.PRINTERS_ADMIN,
    PERMISSIONS.PRODUCTION_LIST,
    PERMISSIONS.SALON_LIST,
    PERMISSIONS.SALON_VIEW,
    PERMISSIONS.SUBSCRIPTION_LIST,
    PERMISSIONS.SUBSCRIPTION_VIEW,
    PERMISSIONS.SURVEY_VIEW,
    PERMISSIONS.TEAMMATES_DETAIL,
    PERMISSIONS.TEAMMATES_EDIT_PERMISSIONS,
    PERMISSIONS.TEAMMATES_EDIT,
    PERMISSIONS.TEAMMATES_LIST,
  ],
};

export const INNOVATION_PRODUCT_MANAGER = {
  name: 'Innovation Product Manager',
  permissions: [
    PERMISSIONS.DATA_PORTAL,
    PERMISSIONS.CUSTOMER_LIST,
    PERMISSIONS.CUSTOMER_VIEW,
    PERMISSIONS.SURVEY_VIEW,
    PERMISSIONS.EXPORTS,
    PERMISSIONS.FAST_CONSULTATION,
    PERMISSIONS.FEEDBACK_LIST,
    PERMISSIONS.ORDER_LIST,
    PERMISSIONS.ORDER_VIEW,
  ],
};

export const INNOVATION_INTERN = {
  name: 'Innovation Intern',
  permissions: [],
};

export const RD_GENERAL = {
  name: 'R&D General',
  permissions: [
    PERMISSIONS.CUSTOMER_LIST,
    PERMISSIONS.CUSTOMER_VIEW,
    PERMISSIONS.SURVEY_VIEW,
    PERMISSIONS.DATA_PORTAL,
    PERMISSIONS.FAST_CONSULTATION,
    PERMISSIONS.FEEDBACK_LIST,
    PERMISSIONS.ORDER_LIST,
    PERMISSIONS.ORDER_VIEW,
  ],
};

export const RD_INTERN = {
  name: 'R&D Intern',
  permissions: [],
};

export const DATA_ANALYSIS = {
  name: 'Data Analysis',
  permissions: [
    PERMISSIONS.CUSTOMER_LIST,
    PERMISSIONS.CUSTOMER_VIEW,
    PERMISSIONS.SURVEY_VIEW,
    PERMISSIONS.DATA_PORTAL,
    PERMISSIONS.EXPORTS,
    PERMISSIONS.FAST_CONSULTATION,
    PERMISSIONS.FEEDBACK_LIST,
    PERMISSIONS.GIFT_DETAIL,
    PERMISSIONS.GIFT_LIST,
    PERMISSIONS.HELP,
    PERMISSIONS.HELP_BOX_OBJECT_VIEWER,
    PERMISSIONS.HELP_STATUS_VIEWER,
    PERMISSIONS.ORDER_LIST,
    PERMISSIONS.ORDER_VIEW,
    PERMISSIONS.SALON_LIST,
    PERMISSIONS.SALON_VIEW,
    PERMISSIONS.SUBSCRIPTION_LIST,
    PERMISSIONS.SUBSCRIPTION_VIEW,
  ],
};

export const DATA_DEVELOPER = {
  name: 'Data Developer',
  permissions: [
    PERMISSIONS.CUSTOMER_LIST,
    PERMISSIONS.CUSTOMER_VIEW,
    PERMISSIONS.SURVEY_VIEW,
    PERMISSIONS.DATA_PORTAL,
    PERMISSIONS.EXPORTS,
    PERMISSIONS.FAST_CONSULTATION,
    PERMISSIONS.FEEDBACK_LIST,
    PERMISSIONS.GIFT_DETAIL,
    PERMISSIONS.GIFT_LIST,
    PERMISSIONS.HELP,
    PERMISSIONS.HELP_BOX_OBJECT_VIEWER,
    PERMISSIONS.HELP_STATUS_VIEWER,
    PERMISSIONS.ORDER_LIST,
    PERMISSIONS.ORDER_VIEW,
    PERMISSIONS.SALON_LIST,
    PERMISSIONS.SALON_VIEW,
    PERMISSIONS.SUBSCRIPTION_LIST,
    PERMISSIONS.SUBSCRIPTION_VIEW,
  ],
};

export const OPS_SUPERVISOR = {
  name: 'Ops Supervisor',
  permissions: [
    PERMISSIONS.AUTO_DISPATCH,
    PERMISSIONS.BUFFER_SPACES_LIST,
    PERMISSIONS.CUSTOMER_LIST,
    PERMISSIONS.CUSTOMER_VIEW,
    PERMISSIONS.DATA_PORTAL,
    PERMISSIONS.DEFECT_ORDER_DETAIL,
    PERMISSIONS.EXPORTS,
    PERMISSIONS.MANUAL_DISPATCH,
    PERMISSIONS.QUALITY_TEST_PALLETIZE_BOXES,
    PERMISSIONS.QUALITY_TEST_PALLETS,
    PERMISSIONS.NEW_FLOW_PRODUCTION_SCREEN,
    PERMISSIONS.OLD_FLOW_PRODUCTION_SCREEN,
    PERMISSIONS.ORDER_LIST,
    PERMISSIONS.ORDER_VIEW,
    PERMISSIONS.PACK_SCREEN,
    PERMISSIONS.PRINT_COLLATERALS,
    PERMISSIONS.PRINTERS_ADMIN,
    PERMISSIONS.PRODUCTION_LIST,
    PERMISSIONS.SURVEY_VIEW,
    PERMISSIONS.TEAMMATES_DETAIL,
    PERMISSIONS.TEAMMATES_EDIT_PERMISSIONS,
    PERMISSIONS.TEAMMATES_EDIT,
    PERMISSIONS.TEAMMATES_LIST,
  ],
};

export const OPS_ASSOCIATE = {
  name: 'Ops Associate',
  permissions: [
    PERMISSIONS.ORDER_LIST,
    PERMISSIONS.ORDER_VIEW,
    PERMISSIONS.AUTO_DISPATCH,
    PERMISSIONS.BUFFER_SPACES_LIST,
    PERMISSIONS.MANUAL_DISPATCH,
    PERMISSIONS.QUALITY_TEST_PALLETIZE_BOXES,
    PERMISSIONS.NEW_FLOW_PRODUCTION_SCREEN,
    PERMISSIONS.OLD_FLOW_PRODUCTION_SCREEN,
    PERMISSIONS.PACK_SCREEN,
    PERMISSIONS.PRINT_COLLATERALS,
    PERMISSIONS.PRODUCTION_LIST,
  ],
};

export const OPS_MANAGEMENT = {
  name: 'Ops Management',
  permissions: [
    PERMISSIONS.AUTO_DISPATCH,
    PERMISSIONS.BUFFER_SPACES_LIST,
    PERMISSIONS.CUSTOMER_LIST,
    PERMISSIONS.CUSTOMER_VIEW,
    PERMISSIONS.SURVEY_VIEW,
    PERMISSIONS.DATA_PORTAL,
    PERMISSIONS.EXPORTS,
    PERMISSIONS.FAST_CONSULTATION,
    PERMISSIONS.FEEDBACK_LIST,
    PERMISSIONS.GIFT_DETAIL,
    PERMISSIONS.GIFT_LIST,
    PERMISSIONS.HELP,
    PERMISSIONS.HELP_BOX_OBJECT_VIEWER,
    PERMISSIONS.HELP_STATUS_VIEWER,
    PERMISSIONS.MANUAL_DISPATCH,
    PERMISSIONS.QUALITY_TEST_PALLETIZE_BOXES,
    PERMISSIONS.NEW_FLOW_PRODUCTION_SCREEN,
    PERMISSIONS.OLD_FLOW_PRODUCTION_SCREEN,
    PERMISSIONS.ORDER_LIST,
    PERMISSIONS.ORDER_VIEW,
    PERMISSIONS.PACK_SCREEN,
    PERMISSIONS.PRODUCTION_LIST,
    PERMISSIONS.PRINT_COLLATERALS,
    PERMISSIONS.PRINTERS_ADMIN,
    PERMISSIONS.SALON_LIST,
    PERMISSIONS.SALON_VIEW,
    PERMISSIONS.SUBSCRIPTION_LIST,
    PERMISSIONS.SUBSCRIPTION_VIEW,
    PERMISSIONS.TEAMMATES_DETAIL,
    PERMISSIONS.TEAMMATES_EDIT,
    PERMISSIONS.TEAMMATES_LIST,
    PERMISSIONS.TEAMMATES_EDIT_PERMISSIONS,
  ],
};

export const CX_MANAGEMENT = {
  name: 'CX Management',
  permissions: [
    PERMISSIONS.CUSTOMER_LIST,
    PERMISSIONS.CUSTOMER_VIEW,
    PERMISSIONS.SURVEY_VIEW,
    PERMISSIONS.COUPONS_VIEW,
    PERMISSIONS.DATA_PORTAL,
    PERMISSIONS.EXPORTS,
    PERMISSIONS.FAST_CONSULTATION,
    PERMISSIONS.FEEDBACK_LIST,
    PERMISSIONS.GIFT_DETAIL,
    PERMISSIONS.GIFT_LIST,
    PERMISSIONS.ORDER_LIST,
    PERMISSIONS.ORDER_VIEW,
    PERMISSIONS.SALON_LIST,
    PERMISSIONS.SALON_VIEW,
    PERMISSIONS.SUBSCRIPTION_LIST,
    PERMISSIONS.SUBSCRIPTION_VIEW,
    PERMISSIONS.TEAMMATES_DETAIL,
    PERMISSIONS.TEAMMATES_EDIT,
    PERMISSIONS.TEAMMATES_LIST,
    PERMISSIONS.TEAMMATES_EDIT_PERMISSIONS,
  ],
};

export const CX_GENERAL = {
  name: 'CX General',
  permissions: [
    PERMISSIONS.CUSTOMER_LIST,
    PERMISSIONS.CUSTOMER_VIEW,
    PERMISSIONS.SURVEY_VIEW,
    PERMISSIONS.COUPONS_VIEW,
    PERMISSIONS.FEEDBACK_LIST,
    PERMISSIONS.GIFT_DETAIL,
    PERMISSIONS.GIFT_LIST,
    PERMISSIONS.ORDER_LIST,
    PERMISSIONS.ORDER_VIEW,
    PERMISSIONS.SUBSCRIPTION_LIST,
    PERMISSIONS.SUBSCRIPTION_VIEW,
  ],
};

export const CX_OUTSOURCED = {
  name: 'CX Outsourced',
  permissions: [
    PERMISSIONS.CUSTOMER_LIST,
    PERMISSIONS.CUSTOMER_VIEW,
    PERMISSIONS.SURVEY_VIEW,
    PERMISSIONS.COUPONS_VIEW,
    PERMISSIONS.ORDER_LIST,
    PERMISSIONS.ORDER_VIEW,
    PERMISSIONS.SUBSCRIPTION_LIST,
    PERMISSIONS.SUBSCRIPTION_VIEW,
  ],
};

export const FINANCE = {
  name: 'Finance',
  permissions: [
    PERMISSIONS.CUSTOMER_LIST,
    PERMISSIONS.CUSTOMER_VIEW,
    PERMISSIONS.SURVEY_VIEW,
    PERMISSIONS.COUPONS_VIEW,
    PERMISSIONS.ORDER_LIST,
    PERMISSIONS.ORDER_VIEW,
    PERMISSIONS.SUBSCRIPTION_LIST,
    PERMISSIONS.SUBSCRIPTION_VIEW,
    PERMISSIONS.EXPORTS,
    PERMISSIONS.DATA_PORTAL,
  ],
};

export const TECH_DEVELOPER = {
  name: 'Tech Developer',
  permissions: [
    PERMISSIONS.AUTO_DISPATCH,
    PERMISSIONS.BUFFER_SPACES_LIST,
    PERMISSIONS.COUPONS_VIEW,
    PERMISSIONS.CUSTOMER_LIST,
    PERMISSIONS.CUSTOMER_VIEW,
    PERMISSIONS.DATA_PORTAL,
    PERMISSIONS.DEFECT_ORDER_DETAIL,
    PERMISSIONS.EXPORTS,
    PERMISSIONS.FAST_CONSULTATION,
    PERMISSIONS.FEEDBACK_LIST,
    PERMISSIONS.GIFT_DETAIL,
    PERMISSIONS.GIFT_LIST,
    PERMISSIONS.HELP_BOX_OBJECT_VIEWER,
    PERMISSIONS.HELP_STATUS_VIEWER,
    PERMISSIONS.HELP,
    PERMISSIONS.MANUAL_DISPATCH,
    PERMISSIONS.QUALITY_TEST_PALLETIZE_BOXES,
    PERMISSIONS.QUALITY_TEST_PALLETS,
    PERMISSIONS.NEW_FLOW_PRODUCTION_SCREEN,
    PERMISSIONS.OLD_FLOW_PRODUCTION_SCREEN,
    PERMISSIONS.ORDER_LIST,
    PERMISSIONS.ORDER_VIEW,
    PERMISSIONS.PACK_SCREEN,
    PERMISSIONS.PRINT_COLLATERALS,
    PERMISSIONS.PRINTERS_ADMIN,
    PERMISSIONS.PRODUCTION_LIST,
    PERMISSIONS.SALON_LIST,
    PERMISSIONS.SALON_VIEW,
    PERMISSIONS.SUBSCRIPTION_LIST,
    PERMISSIONS.SUBSCRIPTION_VIEW,
    PERMISSIONS.SURVEY_VIEW,
    PERMISSIONS.TEAMMATES_DETAIL,
    PERMISSIONS.TEAMMATES_EDIT_PERMISSIONS,
    PERMISSIONS.TEAMMATES_EDIT,
    PERMISSIONS.TEAMMATES_LIST,
  ],
};

export const PERFORMANCE_MARKETING = {
  name: 'Performance Marketing',
  permissions: [
    PERMISSIONS.DATA_PORTAL,
    PERMISSIONS.CUSTOMER_LIST,
    PERMISSIONS.CUSTOMER_VIEW,
    PERMISSIONS.SURVEY_VIEW,
    PERMISSIONS.ORDER_LIST,
    PERMISSIONS.ORDER_VIEW,
    PERMISSIONS.EXPORTS,
    PERMISSIONS.FEEDBACK_LIST,
    PERMISSIONS.ORDER_LIST,
    PERMISSIONS.ORDER_VIEW,
  ],
};

export const ANALYTICS_ENGINEER = {
  name: 'Analytics Engineer',
  permissions: [
    PERMISSIONS.CUSTOMER_LIST,
    PERMISSIONS.CUSTOMER_VIEW,
    PERMISSIONS.SURVEY_VIEW,
    PERMISSIONS.ORDER_LIST,
    PERMISSIONS.ORDER_VIEW,
    PERMISSIONS.COUPONS_VIEW,
    PERMISSIONS.SUBSCRIPTION_LIST,
    PERMISSIONS.SUBSCRIPTION_VIEW,
    PERMISSIONS.FEEDBACK_LIST,
    PERMISSIONS.GIFT_DETAIL,
    PERMISSIONS.GIFT_LIST,
    PERMISSIONS.EXPORTS,
  ],
};

export const BUSINESS_ANALYST = {
  name: 'Business Analyst',
  permissions: [
    PERMISSIONS.CUSTOMER_LIST,
    PERMISSIONS.CUSTOMER_VIEW,
    PERMISSIONS.SURVEY_VIEW,
    PERMISSIONS.ORDER_LIST,
    PERMISSIONS.ORDER_VIEW,
    PERMISSIONS.COUPONS_VIEW,
    PERMISSIONS.SUBSCRIPTION_LIST,
    PERMISSIONS.SUBSCRIPTION_VIEW,
    PERMISSIONS.EXPORTS,
  ],
};

export const teammatesRoles = [
  ADMINS,
  ANALYTICS_ENGINEER,
  BUSINESS_ANALYST,
  CX_GENERAL,
  CX_MANAGEMENT,
  CX_OUTSOURCED,
  DATA_ANALYSIS,
  DATA_DEVELOPER,
  FINANCE,
  INNOVATION_INTERN,
  INNOVATION_PRODUCT_MANAGER,
  OPS_ASSOCIATE,
  OPS_MANAGEMENT,
  OPS_SUPERVISOR,
  PERFORMANCE_MARKETING,
  RD_GENERAL,
  RD_INTERN,
  TECH_DEVELOPER,
];

export default teammatesRoles;
